import(/* webpackMode: "eager" */ "/app/components/landing/hero-client.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/hooks.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/icons/icons.module.css");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/quoted-tweet/quoted-tweet-container.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/theme.css");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/quoted-tweet/quoted-tweet-body.module.css");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-actions-copy.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-container.module.css");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-header.module.css");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-in-reply-to.module.css");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-body.module.css");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/skeleton.module.css");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-info-created-at.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-link.module.css");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-info.module.css");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-actions.module.css");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/quoted-tweet/quoted-tweet-header.module.css");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-media-video.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/verified-badge.module.css");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-media.module.css");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-replies.module.css");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-not-found.module.css");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/react-tweet@3.2.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-tweet/dist/twitter-theme/tweet-skeleton.module.css");
import(/* webpackMode: "eager" */ "/app/registry/components/magicui/fade-in.tsx")